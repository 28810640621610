.svg-icon svg {
    pointer-events: none;
}
.filter-container {
    display: flex;
    flex-wrap: wrap;
    /* Allows items to wrap to a new line */
    justify-content: space-between;
    /* Justifies content on the main axis */
    align-items: flex-start;
    /* Aligns items on the cross axis */
    gap: 16px;
    /* Adds a gap between items (optional, adjust or remove as needed) */
}

.filter-item {
    flex: 1;
    /* Makes each item equal in width, adjust based on your needs */
}

/* Optional: Making the last item (button) full width on smaller screens */
@media (max-width: 768px) {

    /* Adjust breakpoint as needed */
    .filter-item:last-child {
        flex-basis: 100%;
        /* Makes the last item full width */
    }

    .filter-item {
        flex: 1 1;
        min-width: 200px;
    }
}

#file-name {
    display: none !important;
    padding: 0% !important;
    margin: 0% !important;
}

.dTuydp {
    min-height: 1px !important;
}

.sc-imWYAI {
    min-height: 1px !important;
}

.DocViewerCla {
    overflow: auto !important;
    padding: 0% !important;
}

.signature-image {
    width: 250px;
    /* Set your desired width */
    height: auto;
    /* Maintain aspect ratio */
}

.rounded-div {
    border-radius: 2px;
}

.license-number {
    word-break: break-all;
}

//modal full screen V3
// #kt_delivery_performance .apex-bar {
//     clip-path: inset(4% 14% 2px 13% round 183px);
// }

.image-input-wrapper.size {
    width: 90px !important;
}

.reverse-on-small {
    display: flex !important;
    flex-direction: row !important;
    /* Default flex direction */
}

@media (max-width: 1900px) {
    .reverse-on-small {
        flex-direction: column-reverse !important;
        /* Reverse flex on small screens */
    }
}

.min-height-800 {
    min-height: 800px;
}

.bg-color-nowrap {
    background-color: #FDEde6;
    /* Light peach background color */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.card-fixed-height {
    height: 100px;
    /* Fixed height */
    min-height: 100px;
    /* Minimum height */
}

.background-image-class {
    background-image: url("/media/misc/bg-1.jpg");
}

.custom-background-search20 {
    background-image: url("/media/users/300_20.jpg");
}

.custom-background-search15 {
    background-image: url("/media/users/300_15.jpg");
}

.custom-background-search12 {
    background-image: url("/media/users/300_12.jpg");
}

.custom-background-search16 {
    background-image: url("/media/users/300_16.jpg");
}

.error-page {
    font-size: 70px;
    position: absolute;
    left: 43%;
    bottom: 27%;
}

.custom-background-error-page {
    background-image: url('/media/error/404.svg');
    /* Default image */
    background-position: top;
    /* Aligns the background to the top */
    background-size: auto;
    /* Sets the background size to auto */
}

.background-cover-otc {
    background-size: cover;
    /* Ensures the background image covers the entire area */
    background-position: center;
    /* Centers the background image */
}

.flex-center-map {
    display: flex;
    /* Set display to flex */
    align-items: center;
    /* Align items vertically center */
    margin-bottom: 10px;
    /* Add bottom margin */
}

.word-break-all {
    word-break: break-all;
}

.bg-image-4 {
    background-image: url("/media/bg/bg-4.jpg");
}

.custom-element-style-search {
    height: auto !important;
    position: absolute !important;
    right: -5px !important;
    top: 2px !important;
    width: auto !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.relative-position {
    position: relative;
    /* Set the position to relative */
    right: 7px;
    /* Set the right position */
}

.red-background-nowrap {
    background-color: #FF0000;
    /* Set background color to red */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.light-gray-background-nowrap {
    background-color: #ECECEC;
    /* Set background color to light gray */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.no-wrap {
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.max-height-325 {
    max-height: 325px;
    /* Set maximum height */
    overflow: hidden;
    /* Hide overflow content */
}

.width-400 {
    width: 400px;
    /* Fixed width of 400px */
}

.white-space-nowrap {
    white-space: nowrap;
}

.input-number-width {
    width: 270px;
}

.text-align-last-right {
    text-align-last: right;
    /* Aligns last line of text to the right */
}

.input-number-width90 {
    width: 90px;
}

.input-number-width130 {
    width: 130px;
}

.rounded-40 {
    border-radius: 40px;
    /* Set border radius to 40px */
}

.margin-zero {
    margin-right: 0px;
    /* Set right margin to 0 */
    margin-left: 0px;
    /* Set left margin to 0 */
}

.date-picker-custom {
    width: 100%;
    padding: 7px;
    /* You can use Bootstrap's spacing utilities if you prefer */
}

.width-50 {
    width: 50px;
}

.custom-users-list-loading {
    border-radius: 0.475rem;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    /* Use rgba instead of rgb */
    background-color: #fff;
    color: #7e8299;
    font-weight: 500;
    margin: 0;
    width: auto;
    padding: 1rem 2rem;
    top: calc(50% - 2rem);
    left: calc(50% - 4rem);
    position: absolute;
    text-align: center;
}

.custom-users-list-loading2 {
    border-radius: 0.475rem;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    /* Use rgba instead of rgb */
    background-color: #fff;
    color: #7e8299;
    font-weight: 500;
    margin: 0;
    width: 150px;
    padding: 1rem 2rem;
    top: calc(50% - 2rem);
    left: calc(50% - 4rem);
    text-align: center;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-card {
    border-radius: 0.475rem;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    background-color: #fff;
    color: #7e8299;
    font-weight: 500;
    margin: 0;
    width: 200px;
    padding: 1rem 2rem;
    position: relative;
    /* Card positioned relative */
    text-align: center;
}

.x-small {
    font-size: 0.75rem;
    /* Adjust as needed */
}

.custom-Width-Select {
    width: 75px;
}

.min-height-550 {
    min-height: 550px;
}

.pointer-events-none {
    pointer-events: none;
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}

.custom-position-carrier-cost {
    top: 11px;
    right: 3rem;
}

.text-center-custom {
    text-align: center;
}

.custom-element {
    height: 920px;
    width: 100%;
    border-radius: 15px;
}

.custom-border-radius {
    border-radius: 10px;
    height: fit-content;
}

.textStyle {
    text-align: right;
}

.fullWidthPadding {
    width: 100%;
    padding: 7px;
}

.coverFit {
    object-fit: cover;
}

.custom-card {
    background-size: cover;
    position: relative;
}

.borderBox {
    border: 2px solid black;
    border-radius: 5px;
    border-left: none;
    padding: 3px;
}

.borderRight {
    border-right: 3px solid black;
}

.customBorder {
    border: 3px solid black;
    border-radius: 5px;
    padding: 10px;
    color: black;
    width: 8in;
    height: 2in;
    font-family: sans-serif;
    font-weight: bold;
}

.customBorder2 {
    border: 4px solid black;
    padding: 10px;
    color: black;
    width: 4in;
    /* height: 6in; */
    font-family: sans-serif;
    font-weight: bold;
}

.borderBottom {
    border-bottom: 3px solid black;
}

.borderRight {
    border-right: 3px solid black;
}

.largeBoldTextWithBorder {
    font-size: 100px;
    font-weight: bold;
    border-right: 3px solid black;
    text-align: center;
}

.customBorder53 {
    border: 3px solid black;
    padding: 10px;
    color: black;
    width: 5in;
    height: 3in;
    font-family: sans-serif;
    font-weight: bold;
}

.boldText {
    font-size: 14px;
    font-weight: bolder;
}

.custom-checkbox {
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: transparent;
    border-radius: 4px;
    accent-color: #EC520b;
    /* Custom color for checkbox */
    background-color: #EBEDF3;
    /* Default background color */
}

.custom-height {
    height: 150px;
}

.custom-height-Loading {
    height: 3px;
}

.small-text-absolute {
    font-size: 8px;
    position: absolute;
}

.custom-background {
    border-radius: 30px;
    background-image: url("/media/icons/Group1000007988.png"), linear-gradient(to left, #f9d6ae, #f9d6ae, #f9d6ae);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    /* You can also include these Bootstrap classes directly here if needed */
    background-color: rgba(245, 245, 245, 0.7);
    /* Adjust to match bg-gray-100 bg-opacity-70 */
}

.unauthorized-page {
    font-size: 70px;
    position: absolute;
    left: 53%;
    bottom: 43%;
}

.custom-background-unauthorized {
    background-image: url('/media/error/401.svg');
    background-position: top;
    background-size: auto;
    background-repeat: no-repeat;
}

.absolute-unauthorized-page {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    margin-top: 1.25rem !important;
    padding: 2.5rem 1.25rem !important;
    font-weight: bolder !important;
    font-size: 18px !important;
}

.custom-object-fit-cover {
    object-fit: cover;
}

.bg-custom {
    background-image: url('/media/bg/bg-3.jpg');
}

.min-height-300 {
    min-height: 300px;
}

.custom-label {
    background-color: #FFA500 !important;
    height: 10px;
    /* Height */
    width: 10px;
    /* Width */
    display: inline-block;
    /* Ensures the dimensions apply */
}

.custom-max-width {
    max-width: 700px;
}

.custom-max-width100 {
    max-width: 100%;
}

.custom-height-350 {
    height: 350px;
}

.max-height-700 {
    max-height: 700px;
    overflow: hidden;
}

.relative-max-height-700 {
    max-height: 700px;
    position: relative;
}

.custom-background-order {
    background-color: #EC520b;
    white-space: nowrap;
    color: white;
}

.trips.timeline.timeline-6:before {
    content: '';
    position: absolute;
    left: 0px !important;
    width: 3px;
    top: 0;
    bottom: 0;
    background-color: #EBEDF3;
}

.trips.timeline.timeline-6 .timeline-item .timeline-label {
    width: 0px !important;
    flex-shrink: 0;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    color: #3F4254;
}

.bg-color-nowrap {
    background-color: #FDEde6;
    /* Light peach background color */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.card-fixed-height {
    height: 100px;
    /* Fixed height */
    min-height: 100px;
    /* Minimum height */
}

.background-image-class {
    background-image: url("/media/misc/bg-1.jpg");
}

.custom-background-search20 {
    background-image: url("/media/users/300_20.jpg");
}

.custom-background-search15 {
    background-image: url("/media/users/300_15.jpg");
}

.custom-background-search12 {
    background-image: url("/media/users/300_12.jpg");
}

.custom-background-search16 {
    background-image: url("/media/users/300_16.jpg");
}

.error-page {
    font-size: 70px;
    position: absolute;
    left: 43%;
    bottom: 27%;
}

.custom-background-error-page {
    background-image: url('/media/error/404.svg');
    /* Default image */
    background-position: top;
    /* Aligns the background to the top */
    background-size: auto;
    /* Sets the background size to auto */
}

.background-cover-otc {
    background-size: cover;
    /* Ensures the background image covers the entire area */
    background-position: center;
    /* Centers the background image */
}

.flex-center-map {
    display: flex;
    /* Set display to flex */
    align-items: center;
    /* Align items vertically center */
    margin-bottom: 10px;
    /* Add bottom margin */
}

.word-break-all {
    word-break: break-all;
}

.bg-image-4 {
    background-image: url("/media/bg/bg-4.jpg");
}

.custom-element-style-search {
    height: auto !important;
    position: absolute !important;
    right: -5px !important;
    top: 2px !important;
    width: auto !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.relative-position {
    position: relative;
    /* Set the position to relative */
    right: 7px;
    /* Set the right position */
}

.red-background-nowrap {
    background-color: #FF0000;
    /* Set background color to red */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.light-gray-background-nowrap {
    background-color: #ECECEC;
    /* Set background color to light gray */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.no-wrap {
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.max-height-325 {
    max-height: 325px;
    /* Set maximum height */
    overflow: hidden;
    /* Hide overflow content */
}

.width-400 {
    width: 400px;
    /* Fixed width of 400px */
}

.white-space-nowrap {
    white-space: nowrap;
}

.input-number-width {
    width: 270px;
}

.text-align-last-right {
    text-align-last: right;
    /* Aligns last line of text to the right */
}

.input-number-width90 {
    width: 90px;
}

.input-number-width130 {
    width: 130px;
}

.rounded-40 {
    border-radius: 40px;
    /* Set border radius to 40px */
}

.margin-zero {
    margin-right: 0px;
    /* Set right margin to 0 */
    margin-left: 0px;
    /* Set left margin to 0 */
}

.date-picker-custom {
    width: 100%;
    padding: 7px;
    /* You can use Bootstrap's spacing utilities if you prefer */
}

.width-50 {
    width: 50px;
}

.custom-users-list-loading {
    border-radius: 0.475rem;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    /* Use rgba instead of rgb */
    background-color: #fff;
    color: #7e8299;
    font-weight: 500;
    margin: 0;
    width: auto;
    padding: 1rem 2rem;
    top: calc(50% - 2rem);
    left: calc(50% - 4rem);
    position: absolute;
    text-align: center;
}

.custom-users-list-loading2 {
    border-radius: 0.475rem;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    /* Use rgba instead of rgb */
    background-color: #fff;
    color: #7e8299;
    font-weight: 500;
    margin: 0;
    width: 150px;
    padding: 1rem 2rem;
    top: calc(50% - 2rem);
    left: calc(50% - 4rem);
    text-align: center;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-card {
    border-radius: 0.475rem;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    background-color: #fff;
    color: #7e8299;
    font-weight: 500;
    margin: 0;
    width: 200px;
    padding: 1rem 2rem;
    position: relative;
    /* Card positioned relative */
    text-align: center;
}

.x-small {
    font-size: 0.75rem;
    /* Adjust as needed */
}

.custom-Width-Select {
    width: 75px;
}

.min-height-550 {
    min-height: 550px;
}

.pointer-events-none {
    pointer-events: none;
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}

.custom-position-carrier-cost {
    top: 11px;
    right: 3rem;
}

.text-center-custom {
    text-align: center;
}

.custom-element {
    height: 920px;
    width: 100%;
    border-radius: 15px;
}

.custom-border-radius {
    border-radius: 10px;
    height: fit-content;
}

.textStyle {
    text-align: right;
}

.fullWidthPadding {
    width: 100%;
    padding: 7px;
}

.coverFit {
    object-fit: cover;
}

.custom-card {
    background-size: cover;
    position: relative;
}

.borderBox {
    border: 2px solid black;
    border-radius: 5px;
    border-left: none;
    padding: 3px;
}

.borderRight {
    border-right: 3px solid black;
}

.customBorder {
    border: 3px solid black;
    border-radius: 5px;
    padding: 10px;
    color: black;
    width: 8in;
    height: 2in;
    font-family: sans-serif;
    font-weight: bold;
}

.customBorder2 {
    border: 4px solid black;
    padding: 10px;
    color: black;
    width: 4in;
    /* height: 6in; */
    font-family: sans-serif;
    font-weight: bold;
}

.borderBottom {
    border-bottom: 3px solid black;
}

.borderRight {
    border-right: 3px solid black;
}

.largeBoldTextWithBorder {
    font-size: 100px;
    font-weight: bold;
    border-right: 3px solid black;
    text-align: center;
}

.rti--container {
    overflow: overlay !important;
}

.overlay-text-inside {
    font-size: 1.2rem;
    color: #ff5757;
    top: 40%;
}

.borderMessage {
    border-radius: 12px;
    padding: 13px;
    border: dashed;
    margin-top: 2.5rem;
    border-color: gray;
}

.elementTab {
    transition: all 0.4s ease;
}

.btnTab {
    width: 15%;
    font-size: 16px;
}

.w-65 {
    width: 70% !important;
}

.cardHeaderPadding .card-header {
    padding: 0;
}

.img-driver {
    width: 54px;
    border-radius: 8px;
    object-fit: cover;
    height: 44px;
}

.cardNotificationRead {
    cursor: pointer;
    background: #fef3f3;
    border-radius: 10px;
}

.toggleSlider {
    position: absolute;
    top: -1px;
    left: -10px;
    border-radius: 20px;
}

.elementCountSvg {
    position: relative;
    left: 32px;
    color: white;
    z-index: 888;
    top: 12px;
    font-weight: bold;
}

.popup-card {
    position: relative;
    top: -40px;
    left: -15px;
    padding: 5px;
    width: 50%;
    border-radius: 25px;
}

.bg-alerts {
    background-color: rgba(255, 90, 101, 0.08);
}

.scrollAlerts {
    height: 445px !important;
    overflow-y: scroll !important;
}

.cardElementAlert {
    border: 1px solid rgba(233, 234, 243, 1);
    border-radius: 30px;
}

.scrollAlerts::-webkit-scrollbar {
    display: none !important;
}

.w-500-px {
    width: 500px !important;
}

.scroll-list {
    height: 280px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.pagination a :hover {
    background: rgb(206, 203, 203)
}

.pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
    font-size: 15px;
}

.pagination a {
    padding: 10px;
    border-radius: 5px;
    /* color: #F87060; */
    margin-top: auto;
}

.pagination__link {
    font-weight: bold;
    color: #EC520b;
    font-size: 15px;
}

.pagination__link--active a {
    -webkit-text-fill-color: #fff;
    background-color: #EC520b;
    font-size: 15px;
}

.pagination__link--disabled a {
    color: rgb(198, 197, 202);
}



.scale-hover {
    transition: transform 0.3s;
    /* Add a smooth transition */
}

.scale-hover:hover {
    transform: scale(1.05);
    /* Increase size on hover */
}

.modalFullScreenTrips .modal-dialog .modal-content {
    height: auto !important;
}

.modalFullScreenTrips .modal-dialog {
    max-width: 1650px !important;
    transform: translate(0, -30px) !important;
}

.modalFullScreenV3 .modal-dialog .modal-content {
    height: auto !important;
}

.modalFullScreenV3 .modal-dialog {
    max-width: 1934px !important;
    transform: translate(0, -30px) !important;
}

.driver-popover.driverjs-theme {
    background-color: #f1faff;
    color: #000;

}

.driver-popover.driverjs-theme .driver-popover-title {
    font-size: 20px;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
    color: #000;
}

.driver-popover.driverjs-theme button {
    flex: 1;
    text-align: center;
    background-color: #EC520b;
    color: #ffffff;
    text-shadow: none;
    font-size: 14px;
    padding: 5px 8px;
    border-radius: 6px;
}

.driver-popover.driverjs-theme button:hover {
    background-color: #EC520b;
    color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
    justify-content: space-between;
    gap: 3px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
    text-shadow: none !important;
    background-color: #f1faff !important;
    color: #f1faff !important;
    cursor: default;
}

.driver-popover.driverjs-theme .driver-popover-next-btn {
    flex: 1;
    text-align: center;
    background-color: #EC520b;
    color: #ffffff;
    text-shadow: none;
    font-size: 14px;
    padding: 5px 8px;
    border-radius: 6px;
}

.driver-popover.driverjs-theme .driver-popover-prev-btn {
    flex: 1;
    text-align: center;
    background-color: #EC520b;
    color: #ffffff;
    text-shadow: none;
    font-size: 14px;
    padding: 5px 8px;
    border-radius: 6px;
}

@media print {
    @page {
        margin: 0 !important;
    }

    html,
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    .print-content {
        width: 100%;
        height: 100%;
        display: flex;
        position: fixed;
        justify-content: center;
        /* Center horizontally */
        align-items: center;
        /* Center vertically */
    }


    /* Hide the header and footer */
    @page {
        size: auto !important;
    }

    /* You may need to specify the specific class or ID names of your header and footer elements here */
    header,
    footer {
        display: none !important;
    }
}

.custom-button {
    background: linear-gradient(to bottom, #ec520bf1, #e7694cc9, #ec866fb6);
    color: white;
    border: none;
}

//
// Card
//
h2#swal2-title div:where(.swal2-container) .swal-popup-custom div:where(.swal2-icon).swal2-success .swal2-success-ring {
    border: 0.25em solid rgba(255, 255, 255, 0.812) !important;
    /* Set the icon color to green */
}

.alertSkipped {
    font-size: 12px !important;
    padding: 5px !important;
    margin-top: 2rem;
}

div:where(.swal2-container) .swal-popup-custom div:where(.swal2-icon).swal2-success [class^=swal2-success-line] {
    background-color: white !important;
    /* Set the title text color to white */
}

.swal-popup-custom h2#swal2-title {
    color: white !important;
}

.swal-popup-custom div#swal2-html-container {
    color: white !important;
}

.w-80 {
    width: 80%;
}

.font-size-xl {
    font-size: 1.3rem;
}

.prev-button {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 49%;
}

.prev-button-fixed {
    padding: calc(0.825rem + 1px) calc(1.75rem + 1px);
    background-color: #EC520B;
    color: white;
    border: none;
    border-radius: 0px;
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 700;
}

.next-button {
    text-align: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 49%;
}

.next-button-fixed {
    padding: calc(0.825rem + 1px) calc(1.75rem + 1px);
    background-color: #EC520B;
    color: white;
    border: none;
    border-radius: 0px;
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 700;
}

.subheader {
    background-color: #EC520B;
    background-repeat: no-repeat;
}

.subheader-transparent {
    background-color: transparent !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

// Base
@page {
    margin: 20mm 15mm 20mm 15mm;
    width: 100%;
    font-size: small;
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        font-size: 14px;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

.position-unset {
    position: unset !important;
    bottom: unset;
}

body#kt_body {
    height: 100% !important;
}

.react-google-flight-datepicker .date-picker-input {
    height: 38px !important;
}

.react-google-flight-datepicker .date-picker {
    padding: 0 !important;
}

.end-0 {
    right: 0 !important;
}

.top-50 {
    top: 50% !important;
}

.translate-middle-c {
    transform: translate(-10%, -50%) !important;
}

.translate-middle {
    transform: translate(-50%, -50%) !important;
}

.card.card-custom {
    box-shadow: $card-box-shadow;
    border: 0;

    // Header
    >.card-header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        //height: $card-header-height;
        min-height: $card-header-height;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;

        // Title
        .card-title {
            display: flex;
            align-items: center;
            margin: $card-header-spacer-y;
            margin-left: 0;
            //flex-wrap: wrap;

            .card-icon {
                margin-right: 0.75rem;
                line-height: 0;

                i {
                    font-size: 1.25rem;
                    color: $dark-50;
                    line-height: 0;

                    &:after,
                    &:before {
                        line-height: 0;
                    }
                }

                .svg-icon {
                    @include svg-icon-size(24px);
                    @include svg-icon-color($dark-50);
                }
            }

            &,
            .card-label {
                font-weight: 500;
                font-size: 1.275rem;
                color: $dark;
            }

            .card-label {
                margin: 0 0.75rem 0 0;
                flex-wrap: wrap;
            }

            // Description
            small {
                color: $text-muted;
                font-size: 1rem;
            }
        }

        // Toolbar
        .card-toolbar {
            display: flex;
            align-items: center;
            margin: $card-header-spacer-y 0;
            flex-wrap: wrap;
        }

        // Line tabs integration
        &.card-header-tabs-line {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            align-items: stretch;

            .card-toolbar {
                margin: 0;
            }

            .nav {
                border-bottom-color: transparent;

                .nav-item {
                    align-items: stretch;
                }

                .nav-link {
                    padding-top: $card-spacer-y;
                    padding-bottom: $card-spacer-y;
                }
            }
        }

        &.card-header-right {
            justify-content: flex-end;
        }
    }

    // Body
    >.card-body {
        padding: $card-spacer-y $card-spacer-x;
    }

    // Footer
    >.card-footer {
        background-color: transparent;
    }

    // Scroll
    .card-scroll {
        position: relative;
        overflow: auto;
    }

    // Make full height
    &.card-stretch {
        display: flex;
        align-items: stretch !important;
        flex-direction: column;
        height: 100%;

        &.gutter-b {
            height: calc(100% - #{$grid-gutter-width});
        }

        &.card-stretch-half {
            height: 50%;

            &.gutter-b {
                height: calc(50% - #{$grid-gutter-width});
            }
        }

        &.card-stretch-third {
            height: 33.33%;

            &.gutter-b {
                height: calc(33.33% - #{$grid-gutter-width});
            }
        }

        &.card-stretch-fourth {
            height: 25%;

            &.gutter-b {
                height: calc(25% - #{$grid-gutter-width});
            }
        }
    }

    // Card header fit style
    &.card-fit {
        >.card-header {
            border-bottom: 0;
        }

        >.card-footer {
            border-top: 0;
        }
    }

    // Card space style
    &.card-space {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;

        >.card-header {
            padding-left: 0;
            padding-right: 0;
        }

        >form>.card-body,
        >.card-body {
            padding-left: 0;
            padding-right: 0;
        }

        >form>.card-footer,
        >.card-footer {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Sticky card
    &.card-sticky {
        >.card-header {
            transition: left 0.3s, right 0.3s, height 0.3s;
            //height: $card-sticky-header-height;
            min-height: $card-sticky-header-height;
        }
    }

    .card-sticky-on &.card-sticky {
        >.card-header {
            transition: left 0.3s, right 0.3s, height 0.3s;
            position: fixed;
            box-shadow: $card-sticky-shadow;
            z-index: $card-sticky-zindex;
            background: $card-sticky-header-bg;
        }
    }

    // Transparent Background
    &.card-transparent {
        background-color: transparent;
    }

    // No Shadow
    &.card-shadowless {
        box-shadow: none;
    }

    // Reset Padding X
    &.card-px-0 {

        .card-header,
        .card-body,
        .card-footer {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Border style
    &.card-border {
        box-shadow: none;
        border: 1px solid $card-border-color;
    }

    // Collapsed Mode
    &.card-collapsed {

        >form,
        >.card-body {
            display: none;
        }
    }

    .card-header {
        .card-toolbar {
            [data-card-tool="toggle"] {
                i {
                    transition: $transition;
                }
            }
        }
    }

    &.card-collapsed,
    &.card-collapse {
        .card-header {
            .card-toolbar {
                [data-card-tool="toggle"] {
                    i {
                        transition: $transition;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

// Utilities
.card-spacer {
    padding: $card-spacer-y $card-spacer-x !important;
}

.card-spacer-x {
    padding-left: $card-spacer-x !important;
    padding-right: $card-spacer-x !important;
}

.card-spacer-y {
    padding-top: $card-spacer-y !important;
    padding-bottom: $card-spacer-y !important;
}

.card-rounded {
    border-radius: $card-border-radius;
}

.card-rounded-top {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
}

.card-rounded-bottom {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
}

@media (min-width: 992px) {
    .Store-sticky {
        position: sticky;
        top: 1rem;
    }
}

// Mobile mode
@include media-breakpoint-down(sm) {
    .overflow-hidden {
        overflow-y: auto;
    }

    .card.card-custom {

        // Header
        >.card-header:not(.flex-nowrap) {
            min-height: 0;
            padding-top: $card-header-spacer-y;
            padding-bottom: $card-header-spacer-y;
        }
    }
}

// Card related helper classes
.rounded-card {
    @include border-radius($card-border-radius);
}

.rounded-card-top {
    @include border-top-left-radius($card-border-radius);
    @include border-top-right-radius($card-border-radius);
}

.rounded-card-bottom {
    @include border-bottom-left-radius($card-border-radius);
    @include border-bottom-right-radius($card-border-radius);
}

.start-50 {
    left: 50% !important;
}

div[title*="animated-marker-CarGreenNew-true"] {
    transition: all 5s ease;
    background-image: url('/media/svg/icons/Map/CarBlue.svg');
    background-size: cover;
    background-position: center;
}

div[title*="animated-marker-CarGreenNew-false"] {
    background-image: url('/media/svg/icons/Map/CarBlue.svg');
    background-size: cover;
    background-position: center;
}

div[title*="animated-marker-CarGreen-true"] {
    transition: all 5s ease;
    background-image: url('/media/svg/icons/Map/CarGreen.svg');
    background-size: cover;
    background-position: center;
}

div[title*="animated-marker-CarGreen-false"] {
    background-image: url('/media/svg/icons/Map/CarGreen.svg');
    background-size: cover;
    background-position: center;
}

div[title*="animated-marker-CarBlue-true"] {
    transition: all 5s ease;
    background-image: url('/media/svg/icons/Map/CarBlue.svg');
    background-size: cover;
    background-position: center;
}

div[title*="animated-marker-CarBlue-false"] {
    background-image: url('/media/svg/icons/Map/CarBlue.svg');
    background-size: cover;
    background-position: center;
}



.banner-gradient-2 {
    z-index: -1;
    background-image: linear-gradient(166deg, #FFA500, #EC520B);
    opacity: .6;
    filter: blur(160px);
    border-radius: 200px;
    width: 100%;
    max-width: 1000px;
    height: 300px;
    position: absolute;
    top: auto;
    bottom: 10%;
    left: auto;
    right: 3%;
}

@media screen and (min-width: 1440px) {
    .banner-gradient-2 {
        right: 15%;
    }
}

@media screen and (min-width: 1280px) {
    .banner-gradient-2 {
        bottom: 15%;
        right: 10%;
    }
}

.start-with-us-wrapper {
    background-image: url('/media/bg/Motif.svg');
    z-index: 2;
    background-color: #ECEFF3;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
    background-size: contain;
    background-position: unset;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.chart-container {
    width: 100%;
    min-height: 300px;
}

@media (max-width: 480px) {
    .chart-container {
        height: 400px !important;
    }
}