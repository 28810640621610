.bottomCard .timeline.timeline-6:before {
  left: 13px;
  background-color: transparent;
    border-style: dashed;
    border-color: #EC520B;
    border-width: 1px;
    width: 0px
}
.bottomCard .timeline.timeline-6 .timeline-item .timeline-label {
  width: 12px;
}
.mapContainer {
    height: 100%;
    width: 90vh;
    position: relative;
  }
  
  .mapCard {
    height: 100%;
    width: 90vh;
  }
  
  .mapContainer .topCard {
    position: absolute !important;
    top: 10px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    padding: 10px 20px !important;
    background-color: white !important;
    border-radius: 4px !important;
    box-shadow: "0 2px 6px rgba(0,0,0,0.1)";
    z-index: 1000 !important;
    height: auto !important;
    width: 80% !important;
    border-radius: 15px !important;
    background-color: black;
  }
  
  .mapContainer .bottomCard {
    position: absolute !important;
    bottom: 30px !important;
    left: 180px  !important;
    transform: translateX(-50%) !important;
    padding: 10px 20px !important;
    margin-top: 40px !important;
    background-color: white !important; 
    border-radius: 4px !important;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1) !important;
    z-index: 1000 !important; 
    width: 40% !important; 
    height: auto !important;
    border-radius: 15px !important;
  }
  
  @media (max-width: 768px) {
    .mapContainer .bottomCard {
      position: fixed !important;
      bottom: 0px !important;
      left: 0 !important;
      transform: none !important;
      width: 100% !important;
      border-radius: 15px 15px 0 0 !important;
    

    }
  }